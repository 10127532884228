import Image from "next/image";

function RamenShopSection() {
  return (
    <div>
      <div className="box container bg-black">
        <div className="box1">
          <Image src={'/assets/images/remen-shop.png'} width={"377px"}  height={"420px"} alt={""} className="ramen-section-img"></Image>
        </div>
        <div className="box2">
          <div className="innerBox">
            <div className="ramen-section">
              <div className="stick stick-white">
                {/* <Image
                  className="stick-img"
                  src={'/assets/images/white-stick.png'}
               
                /> */}
                <h5>Ramen Shop</h5>
                <p>
                  The known world, a barren land, comes wanderers from another
                  universe seeking answers for the lost world. In their journey,
                  they have stumbled upon a peculiar place, a ramen shop run by
                  an old man. Little did they know, that place holds all secrets
                  for they have encountered one of the SoulZ warriors, the
                  Keeper of Secrets. From here on, history unfolds..
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="box3">
          <div className="image-container">
            <Image className="ramen-section-img" src={'/assets/images/1of1-female.png'} layout={"fill"} alt={""} />
            <Image className="ramen-section-img" src={'/assets/images/1of1-male.png'} layout={"fill"} alt={""} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RamenShopSection;
