// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import dynamic from "next/dynamic";
import dynamic from "next/dynamic";
import Image from "next/image";
import StickBL from "../../../../assets/images/black-stick.png";

const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

// const OwlCarousel = dynamic(() => import("react-owl-carousel"), {

//   ssr: false,

// });

function Montosection() {
  return (
    <div className="slider-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6 monogatari-left-padding">
            <div className="mid-section">
              <div className="stick stick-black">
                {/* <Image
                  className="stick-img"
                  src={StickBL}
                  width={54}
                  height={343}
                /> */}
                <h5>Monogatari</h5>
                <p>
                  3027 AD. An evil regime has taken over the known world.
                  Everything changed when a great force was released by the
                  attack brought upon by the New Order. Orbs of great power were
                  dispersed across the world. Only 7,777 chosen warriors have
                  gathered to be on a mission to obtain the orbs and use it to
                  fight back in the hopes of regaining what was lost. Their
                  heroism has been kept for their tales to be passed on. This is
                  the story of SoulZ.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 pad-hero-mob-left">
            <div className="red-section">
              {/* <img src="/assets/images/anime3.gif" /> */}
              <div className="container1">
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  loop
                  nav={false}
                  dots={false}
                  autoplay={true}
                  animateIn={"fade"}
                >
                  <div className="item">
                    <Image
                      src={"/assets/images/g11.png"}
                      width={"636px"}
                      height={"626px"}
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <Image
                      src={"/assets/images/g22.png"}
                      width={"636px"}
                      height={"626px"}
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <Image
                      src={"/assets/images/g33.png"}
                      width={"636px"}
                      height={"626px"}
                      alt=""
                    />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Montosection;
