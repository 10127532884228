import React, { useEffect } from "react";
import $ from "jquery";
import Image from "next/image";

const Loader = () => {
  useEffect(() => {
    $(document).ready(function () {
      setTimeout(function () {
        $(".loader").fadeOut();
      }, 700);
    });
  });
  return (
    <>
      <div className="loader">
        <Image src={'/assets/images/soulz-loader.gif'} alt="Loader" width={'120px'} height={'120px'}/>
      </div>
    </>
  );
};

export default Loader;
