import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Link from "next/link";

function HomePage() {
  return (
    <div className="container-fluid container-height">
      <div className="row">
        <div className="col-md-6">
          <div className="hero-text-pos">
            <h4 className="hero-text">
              <svg className="banner-text" width="100%" height="100">
                <text
                  fill="black"
                  fillOpacity="0.4"
                  fontSize="80"
                  x="188"
                  y="85"
                  textAnchor="middle"
                  stroke="#ffffff"
                >
                  Public Sale
                </text>
              </svg>
              <br />
              <span className="sold-text">Sold Out!</span>
              <Link
                className="hero-btn-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/collection/soulz-monogatari7777"
              >
                <div className="hero-btn">Buy now on OpenSea</div>
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
