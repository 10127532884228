import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import styles from "../styles/Home.module.css";
import Home from "../views/Home";

const Index: NextPage = () => {
  return <Home />;
};

export default Index;
