import HomePage from "./components/HomePage";
import OrbOfZSection from "./components/OrbOfZ";
import RamenShopSection from "./components/RamenShop";
import FooterHomeSection from "./components/FooterHome";
import { MainSection } from "./styles";
import Nav from "../../nav";
// import MontoSection from "./components/MonogatariSection";
import Montosection from "./components/MonogatariSection";
import Loader from "./loader";

const Home = () => {
  return (
    <MainSection className="App">
      <Loader />
      <Nav />
      <div className="main">
        <HomePage />
        <Montosection />
        <OrbOfZSection />
        <RamenShopSection />
        <FooterHomeSection />
      </div>
    </MainSection>
  );
};
export default Home;
