import styled from "styled-components";

export const MainSection = styled("div")`
  @font-face {
    font-family: "a Asian Hiro";
    src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot");
    src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot?#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff2")
        format("woff2"),
      url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff")
        format("woff"),
      url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.ttf")
        format("truetype"),
      url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.svg#a Asian Hiro")
        format("svg");
    font-display: block;
  }
  @media all and (max-width: 767px) {
    .orb-of-z .col-md-6 {
      order: 2;
    }
  }
  .faq-link {
    font-family: "Lato";
    color: #ffffff;
    text-decoration: none;
  }
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #d5372a;
  }
  .navbar {
    background-color: #141e1e !important;
    max-height: 122px;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .topnav {
    overflow: hidden;
    background-color: #141e1e;
  }
  .logo-position {
    padding-left: 31px;
    padding-top: 16px;
  }
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    /* font-size: 17px; */
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    padding-top: 41px;
    /* height: 40px; */
    padding-bottom: 20px;
  }
  .topnav a:hover {
    background-color: transparent;
    color: #ddd;
  }
  .topnav a.active {
    background-color: transparent;
    color: white;
  }
  .topnav-right {
    float: right;
    padding-right: 60px;
  }
  .container-height {
    height: 532px;
    background-color: #141e1e;
    padding-top: 120px;
  }
  .hero-text-pos {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    padding: 200px;
  }
  .hero-text {
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
    color: #ffffff;
    max-width: 500px;
  }
  @media (max-width: 600px) {
    .hero-text {
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      color: #ffffff;
      max-width: 220px;
    }
  }

  .sold-text {
    font-weight: bold;
  }
  .red-section {
    background-image: url(/assets/images/red-circle-1.png);
    width: 100%;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    margin-top: -250px;
    height: calc(100% + 250px);
    // background-size: 720px;
  }

  .owl-carousel .owl-stage-outer,
  .owl-carousel,
  .owl-carousel .owl-stage {
    height: calc(100% + 50px);
  }
  .owl-carousel .owl-item {
    height: 100%;
    // min-width:100%;
  }

  .owl-carousel .owl-item .item > span {
    position: static !important;
  }
  // .red-section img{
  //   width: 95%;
  //   height: 100%;
  //   padding-bottom: 0;
  //   margin-bottom: 0;
  // }
  .hero-btn {
    font-family: "Lato";
    background-image: url(/assets/images/button-bg.png);
    width: 224.8px;
    height: 51.24px;
    width: 224.8px;
    height: 51.24px;
    text-align: center;
    display: flex;
    place-content: center;
    align-items: center;
    color: #d5372a;
    font-size: 19.21px;
    cursor: pointer;
    margin-top: 20px;
  }
  .hero-btn-link {
    text-decoration: none;
  }
  . -section {
    background-color: #ffffff;
    padding: 0px 30px 10px 0px;
    // text-align: justify;
  }
  .mid-section h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    height: 52px;
    padding-top: 2px;
  }
  .mid-section p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #141e1e;
  }
  .monogatari-img {
    display: flex;
    place-content: center;
    align-items: center;
    background: #ffffff;
    padding-right: 0px;
    width: 70px;
    margin-left: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .monogatari-left-padding {
    padding-left: 0;
    padding-top: 35px;
    padding-bottom: 35px;
    background: #ffffff;
  }
  .orb-of-z {
    background: #141e1e;
  }

  .mid-section-orb {
    background-color: transparent;
    padding: 30px 60px 10px 0px;
    text-align: justify;
  }
  .mid-section-orb h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 80px;
    color: #ffffff;
  }
  .mid-section-orb p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */
    color: #ffffff;
  }
  .orbofz-img {
    display: flex;
    place-content: center;
    align-items: center;
    background: transparent;
  }
  .orbofz-images {
    display: block;
    place-content: space-around;
    align-items: center;
    text-align: center;
  }
  .orbofz-images > div {
    display: inline-block;
  }
  .ramen-section {
    background-color: #534e4e;
    padding: 23px 30px 10px 0px;
    text-align: justify;
    height: 100%;
    place-content: center;
    display: flex;
    flex-direction: column;
  }
  .ramen-section-img {
    width: 100%;
    height: 100%;
  }
  .ramen-section h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    color: #ffffff;
  }
  .ramen-section p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */
    color: #ffffff;
  }
  .ramen-img {
    display: flex;
    place-content: center;
    align-items: center;
    background: #534e4e;
  }
  .footer-bg {
    background-image: url(/assets/images/bg2.png);
  }
  .footer-section {
    height: auto;
    // max-height: 800px;
    padding-bottom: 0px;
    background-image: url(/assets/images/footer-bg.png);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .footer-section h4 {
    text-align: center !important;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 64px;
    /* identical to box height, or 128% */
    font-feature-settings: "pnum" on, "lnum" on;
    color: #141e1e;
    padding-top: 57px;
    padding-bottom: 30px;
  }

  /* Accordion styles */
  .tabs {
    border-radius: 0px;
    overflow: hidden;
  }

  .tab {
    width: 51em;
    color: white;
    overflow: hidden;
    margin: 0 auto;
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 3em;
    background: #141e1e;
    // font-weight: bold;
    cursor: pointer;
    /* Icon */
    height: 104px;
    align-items: center;
    font-family: "Lato";
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #f8f9fa;
  }
  .tab-label:hover {
    background: #141e1e;
  }
  .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    // transition: all 0.35s;
    transform: rotate(90deg);
  }
  .tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: #141e1e;
    // transition: all 0.35s;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }
  .tab-close:hover {
    background: #141e1e;
  }

  input:checked + .tab-label {
    background: #141e1e;
    margin-bottom: 0;
    padding-bottom: 0;
    height: auto;
    padding-top: 25px;
  }
  input:checked + .tab-label::after {
    transform: rotate(90deg);
  }
  input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 10px 50px 10px 50px;
    margin-bottom: 10px;
    line-height: 2em;
  }
  .tab-content a {
    color: #fff;
    word-break: break-all;
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .line-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 1px;
    width: 100%;
    background: #141e1e;
    border: 2px solid #141e1e;
    opacity: 1;
    margin-top: 69px;
    border-radius: 4px;
  }
  .footer-contact {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    margin-right: 5px;
    /* identical to box height, or 160% */
    color: #141e1e;
  }
  .footer-email {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    /* or 200% */
    color: #141e1e;
    line-height: 27px;
  }
  .footer-email a {
    color: #141e1e;
    text-decoration: none;
    font-family: "Lato";
  }
  .footer-follow {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    /* identical to box height, or 160% */
    color: #141e1e;
  }
  .footer-social {
    display: flex;
    align-items: baseline;
  }
  .card1 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/str1.png") no-repeat;
  }
  .card1:hover {
    background: url("/assets/images/str2.png") no-repeat;
    cursor: pointer;
  }
  .card2 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/stn1.png") no-repeat;
  }
  .card2:hover {
    background: url("/assets/images/stn2.png") no-repeat;
    cursor: pointer;
  }
  .card3 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/creat1.png") no-repeat;
  }
  .card3:hover {
    background: url("/assets/images/creat2.png") no-repeat;
    cursor: pointer;
  }
  .card4 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/help1.png") no-repeat;
  }
  .card4:hover {
    background: url("/assets/images/help2.png") no-repeat;
    cursor: pointer;
  }
  .card5 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/fir1.png") no-repeat;
  }
  .card5:hover {
    background: url("/assets/images/fir2.png") no-repeat;
    cursor: pointer;
  }
  .card6 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/int1.png") no-repeat;
  }
  .card6:hover {
    background: url("/assets/images/int2.png") no-repeat;
    cursor: pointer;
  }
  .card7 {
    width: 233px;
    height: 309px;
    background: url("/assets/images/asc1.png") no-repeat;
  }
  .card7:hover {
    background: url("/assets/images/asc2.png") no-repeat;
    cursor: pointer;
  }
  .flex-container {
    display: flex;
    align-items: stretch;
    background-color: #f1f1f1;
  }

  .flex-container > div {
    background-color: #141e1e;
    color: white;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
  }
  .top {
    animation-name: fade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-direction: alternate;
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .container1 {
    position: relative;
    display: block;
    width: 100%;
    /* max-width: 400px; */
    height: 81.8%;
    margin: 0 auto;
    left: -68px;
  }
  .pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    // -webkit-animation: fade 8s infinite;
    // animation: fade 8s infinite;
  }
  #pic1 {
    // animation-delay: 0s;
    background-image: url("/assets/images/g11.png");
    background-size: cover;
  }
  #pic2 {
    background-image: url("/assets/images/g22.png");
    background-size: cover;
    // animation-delay: 4s;
    // opacity:0;
  }
  #pic3 {
    background-image: url("/assets/images/g33.png");
    // animation-delay: 8s;
    background-size: contain;
    position: absolute;
    background-position: center, center;
    left: 119px;
    width: 100%;
    height: 100%;
    z-index: 100;
    // opacity:0;
  }
  // #pic4 {
  //   background-image: url('/assets/images/g4.png');
  //   animation-delay: 32s;
  //   background-size:cover;
  //     position: absolute;
  //     left: 119px;
  //     width: 100%;
  // }
  // @-webkit-keyframes fade {
  //   0% {
  //     opacity: 1;
  //   }
  //   20% {
  //     opacity: 1;
  //   }
  //   40% {
  //     opacity: 0;
  //   }
  //   60% {
  //     opacity: 0;
  //   }
  //   80% {
  //     opacity: 0;
  //   }
  //   100% {
  //     opacity: 1;
  //   }
  // }
  // @keyframes fade {
  //   0% {
  //     opacity: 1;
  //   }
  //   20% {
  //     opacity: 1;
  //   }
  //   40% {
  //     opacity: 0;
  //   }
  //   60% {
  //     opacity: 0;
  //   }
  //   80% {
  //     opacity: 0;
  //   }
  //   100% {
  //     opacity: 1;
  //   }
  // }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.55);
    color: #ffffff;
    padding: 14px 16px;
  }
  .ramen-padding {
    padding-right: 0px;
    padding-left: 0px;
  }
  #onhover {
    display: none;
    transition: all 10s ease-in-out;
    cursor: pointer;
  }
  #surround:hover span[id="initial"] {
    display: none;
  }
  #surround:hover span[id="onhover"] {
    display: block;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .image-container {
    min-height: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .image-container span {
    position: absolute !important;
    display: block;
    animation-name: multiple-image-crossfade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    width: 100% !important;
    height: 100% !important;
    top: 0;
  }
  .image-container > span:first-child {
    animation-delay: 2s;
  }
  .image-container > span:nth-child(2) {
    animation-delay: 0s;
  }

  .image-container > span img {
    width: 100%;
    height: 100%;
  }

  @keyframes multiple-image-crossfade {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bg-black {
    background-color: #141e1e;
  }

  #cf img {
    animation-name: cf3FadeInOut;
    animation-timing-function: ease-in-out;
    /* if you only want to cycle a finite amount of times, 
 simply change 'infinite' with # of iterations you need */
    animation-iteration-count: infinite;
    animation-duration: 6s;
    animation-direction: alternate; /* not strictly necessary */
    position: absolute;
    height: 100%;
    left: -7%;
    background-size: cover;
  }

  #cf img:nth-of-type(1) {
    animation-delay: 5s;
  }

  #cf img:nth-of-type(2) {
    animation-delay: 3s;
  }

  #cf img:nth-of-type(3) {
    /* add some delay for the first picture as well */
    animation-delay: 1s;
  }

  @keyframes cf3FadeInOut {
    /* distributing times evenly */
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media max-width(992px) {
    .show {
      position: absolute;
      background: #141e1e;
      left: 0;
      right: 0;
      top: 100%;
      padding: 30px;
      height: calc(100vh - 123px);
    }
    .show a {
      text-align: center;
      font-size: 35px;
      padding: 15px 0;
    }
  }
  @media only screen and (max-width: 600px) {
    .pad-hero-mob-left {
      padding-left: 0px;
    }
    .tab {
      width: 24em;
      color: white;
      overflow: hidden;
      margin: 0 auto;
    }
    .ramen-section-img {
      /* width: 100%; */
      /* height: 100%; */
      height: 420px;
      width: 100%;
    }
    .image-container {
      height: 420px;
      width: 100%;
    }
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 0.55);
      border-color: rgba(255, 255, 255, 0.7);
    }
    .logo-position {
      padding-left: 20px;
      padding-top: 0;
    }
    .navbar {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: #141e1e !important;
      padding: 0.5rem 2rem 0px 0px;
      height: 122px;
    }
    .footer-contact {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 30px;
      color: rgb(20, 30, 30);
      text-align: center;
    }
    .footer-email {
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      color: rgb(20, 30, 30);
      margin-bottom: 20px;
      text-align: center;
    }
    .footer-follow {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 24px;
      color: rgb(20, 30, 30);
      text-align: center;
    }
    .footer-social {
      display: flex;
      -webkit-box-align: baseline;
      align-items: baseline;
      place-content: space-between;
      margin: auto;
    }
    // .footer-social {
    //   display: -webkit-box;
    //   display: -webkit-flex;
    //   display: -ms-flexbox;
    //   display: flex;
    //   -webkit-align-items: baseline;
    //   -webkit-box-align: baseline;
    //   -ms-flex-align: baseline;
    //   align-items: baseline;
    //   width: 100%;
    //   /* place-content: space-between; */
    //   text-align: center;
    //   align-items: center;
    //   place-content: space-between;
    // }
    .hero-btn-link {
      -webkit-text-decoration: none;
      text-decoration: none;
      text-align: center;
      display: flex;
      place-content: center;
      align-items: center;
    }
    .ramen-section {
      background-color: #534e4e;
      padding: 0px 30px 10px 30px;
      text-align: justify;
      height: 100%;
      place-content: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .ramen-padding {
      padding-right: 15px;
      padding-left: 15px;
    }
    .ramen-img {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      place-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #534e4e;
      display: none;
    }
    .orbofz-images {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      place-content: space-between;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
    }
    .mid-section-orb {
      background-color: transparent;
      padding: 0px 30px 10px 30px;
      text-align: justify;
    }
    .orbofz-img {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      place-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: transparent;
      display: none;
    }
    .monogatari-left-padding {
      padding-left: 15px;
    }
    .container-height {
      background-color: #141e1e;
      height: auto;
      padding-bottom: 60px;
    }

    .container1 {
      position: relative;
      display: block;
      width: 100%;
      height: 93.9%;
      margin: 0 auto;
      left: -175px;
      top: 0;
    }
    .hero-text {
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      color: #ffffff;
      width: 100%;
    }
    .sold-text {
      font-weight: bold;
    }
    .mid-section {
      background-color: #ffffff;
      padding: 0px 0px 0px 0px;
      text-align: justify;
    }
    .monogatari-img {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      place-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #ffffff;
      display: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
      align-items: flex-end !important;
      place-content: flex-end !important;
      text-decoration: none;
      font-size: 30px;
      line-height: 40px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
    }
    .navbar-dark .navbar-nav .nav-link {
      color: #ffffff;
      padding: 15px 20px;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      background: #141e1e;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
      padding-right: 60px;
      /* padding: 15px 20px; */
    }
  }

  .box {
    display: flex;
    place-content: center;
    align-items: center;
    padding: 0;
  }
  .box1 {
    width: 40%;
    height: 420px;
    position: relative;
  }
  .box1 > span {
    display: block !important;
  }
  .box2 {
    width: 60%;
    height: 420px;
    background-color: #534e4e;
  }
  .box3 {
    width: 40%;
    height: 420px;
    position: relative;
  }
  @media (max-width: 991px) {
    .box {
      flex-direction: column;
    }
    .box1,
    .box2,
    .box3 {
      width: 100%;
      height: auto;
    }
    .box3 {
      height: 450px;
    }
    .box2 .ramen-section {
      padding: 30px 30px 0px 0px;
    }
  }
  .innerBox {
    display: flex;
    padding: 40px;
    background-color: #534e4e;
    /* width: 364px; */
    height: 420px;
    display: flex;
    place-content: center;
    align-items: center;
  }
  .faq-line {
    height: 2px;
    border-width: 0;
    color: #ffffff;
    background-color: #ffffff;
    opacity: 1;
  }
  .owl-carousel {
    top: 50px;
  }
  // .owl-carousel.owl-drag .owl-item {
  //   opacity: 0;
  //   transition:all 0.5s ease-in-out;
  // }
  // .owl-carousel.owl-drag .owl-item.active {
  //   opacity: 1;
  // }
  .home-page-container {
    width: 90%;
    position: relative;
    z-index: 2;
    margin-bottom: -1px;
  }
  @media (max-width: 1399px) {
    .red-section {
      margin-top: -130px;
    }
  }
  @media (max-width: 1200px) {
    .col-6.monogatari-left-padding p {
      font-size: 12px;
      line-height: 1.4;
    }
  }
  @media (max-width: 990px) {
    .red-section {
      margin-top: 30px;
      height: 100%;
    }
    .container1 {
      left: -18px;
    }
  }
  @media (max-width: 600px) {
    .col-6.monogatari-left-padding p {
      font-size: 10px;
    }
    .innerBox {
      height: 500px;
    }
  }
  .container-height {
    padding-top: 0;
    heigth: 100%;
  }
  @media (max-width: 600px) {
    .hero-text-pos {
      height: 350px;
      padding: 0;
    }

    .banner-text text {
      transform: translateX(-107px);
    }
    .sold-text {
      font-size: 35px;
      margin-top: 0px;
      display: block;
      margin-bottom: 0px;
    }
  }
  .footer-right {
    justify-content: right;
  }
  .footer-right,
  .footer-left {
    display: flex;
    padding: 50px 0;
  }
  @media all and (max-width: 767px) {
    .footer-right,
    .footer-left {
      flex-direction: column;
      text-align: center;
    }
    .footer-left {
      padding-bottom: 0;
    }
    .footer-right {
      padding-top: 0;
    }
    .footer-contact {
      font-size: 20px;
      line-height: 0px;
    }
    .footer-email {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .footer-follow {
      font-size: 20px;
      line-height: 25px;
    }
  }
  @media all and (max-width: 767px) {
    .mid-section-orb h5 {
      font-size: 40px;
    }
    .logo-position {
      width: 100px;
    }
    .pad-hero-mob-left {
      position: absolute;
      width: 100%;
      left: 0;
      top: -70px;
      background: #141e1e;
      overflow: hidden;
    }
    .red-section {
      background-position: top center;
      max-width: 350px;
      margin: auto;
    }
    .slider-container {
      position: relative;
    }
    .monogatari-left-padding {
      padding-top: 350px;
    }
    .orbofz-images > div {
      height: 220px;
    }
    .orb-of-z .col-md-6 .col-md-6.text-center {
      height: 220px;
    }
    .mid-section {
      padding: 0px 30px 0px 0px;
    }
    .sold-text {
      line-height: 1.2;
    }
    .intro-bg {
      min-height: 690px;
      overflow-y: auto;
    }
  }
`;
