import Image from "next/image";
function OrbOfZSection() {
  return (
    <div>
      <div
        className="container home-page-container orb-of-z"
        style={{ paddingTop: "50px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center">
                  <span id="surround">
                    <span id="initial">
                      <Image src={"/assets/images/str22.png"}  width={"233"} height={"309px"} alt={""}></Image>
                    </span>
                    <span id="onhover">
                      <Image src={"/assets/images/str1.png"} width={"233"} height={"309px"}  alt={""}></Image>
                    </span>
                  </span>
                </div>
                <div className="col-md-6 text-center">
                  <span id="surround">
                    <span id="initial">
                      <Image src={"/assets/images/stn22.png"} width={"233"} height={"309px"}  alt={""}></Image>
                    </span>
                    <span id="onhover">
                      <Image src={"/assets/images/stn1.png"} width={"233"} height={"309px"} alt={""}></Image>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="mid-section-orb">
              <div className="stick stick-white">
                {/* <Image
                  className="stick-Image"
                  src={'/assets/images/white-stick.png'}
                  
                /> */}
                <h5>Orbs of Z</h5>
                <p>
                  When the planet was attacked, eight Orbs of great force and
                  energy were released by the New Order. When collected, it
                  grants the holder incredible power corresponding to the force
                  each Orb contains. The people of Z had to gather them all to
                  stand a chance for survival against this evil that has taken
                  over.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container home-page-container orb-of-z"
        style={{ paddingBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-12 orbofz-images">
            <div>
              <span id="surround">
                <span id="initial">
                  <Image src={"/assets/images/creat22.png"} width={"233"} height={"309px"}  alt={""}></Image>
                </span>
                <span id="onhover">
                  <Image src={"/assets/images/creat1.png"} width={"233"} height={"309px"}  alt={""}></Image>
                </span>
              </span>
            </div>
            <div>
              <span id="surround">
                <span id="initial">
                  <Image src={"/assets/images/help22.png"} width={"233"} height={"309px"} alt={""}></Image>
                </span>
                <span id="onhover"> 
                  <Image src={"/assets/images/help1.png"} width={"233"} height={"309px"} alt={""}></Image>
                </span>
              </span>
            </div>
            <div>
              <span id="surround">
                <span id="initial">
                  <Image src={"/assets/images/fir22.png"} width={"233"} height={"309px"} alt={""}></Image>
                </span>
                <span id="onhover">
                  <Image src={"/assets/images/fir1.png"} width={"233"} height={"309px"}  alt={""}></Image>
                </span>
              </span>
            </div>
            <div>
              <span id="surround">
                <span id="initial">
                  <Image src={"/assets/images/int22.png"}  width={"233"} height={"309px"} alt={""}></Image>
                </span>
                <span id="onhover">
                  <Image src={"/assets/images/int1.png"} width={"233"} height={"309px"}  alt={""}></Image>
                </span>
              </span>
            </div>
            <div>
              <span id="surround">
                <span id="initial">
                  <Image src={"/assets/images/asc22.png"}  width={"233"} height={"309px"} alt={""}></Image>
                </span>
                <span id="onhover">
                  <Image src={"/assets/images/asc1.png"}  width={"233"} height={"309px"} alt={""}></Image>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrbOfZSection;
