import Image from "next/image";
import Link from "next/link";
import router from "next/router";
import { useState } from "react";

const Nav = () => {
  const [SubMenu, setSumMenu] = useState(false);
  const [ShowMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setSumMenu(!SubMenu);
  };
  const showMenuBtn = () => {
    setShowMenu(!ShowMenu);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" href="/">
            <Image
              className="logo-position"
              src={'/assets/images/logo-soulz.png'}
              margin-right="5px"
              margin-left="5px"
              height={"90px"}
              width={"90px"}
              alt={""}
            />
          </Link>
          <button
            onClick={showMenuBtn}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              "collapse navbar-collapse " + (ShowMenu ? "show" : "hidden")
            }
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav">
              <Link aria-current="page" href="/">
                <a className={"active"}>Home</a>
              </Link>
              <Link className="nav-link" href="/devteam">
                Team
              </Link>
              <Link className="nav-link" href="/roadmap">
                <a>Roadmap</a>
              </Link>
              {/* <a className="nav-link" href="/home#faq">
                FAQ
              </a> */}
              <Link className="nav-link" href="/gallery">
                Gallery
              </Link>
              <Link
                className="nav-link"
                href="https://soulz-xyz.web.app/"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Stake
              </Link>

              <Link className="nav-link active" href="/claim">
                <a>Claim</a>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
